<template>
    <div class="table">
        <el-table border ref="multipleTable" :data="tableData" :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}" tooltip-effect="dark" style="width: 100%"
            height="60vh"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>

            <el-table-column prop="content" label="内容" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="name" label="用户名称" width="120">
            </el-table-column>
            <el-table-column prop="contact" label="联系方式" width="120">
            </el-table-column>
            <el-table-column prop="add_time" label="时间"  width="200">

            </el-table-column>
            <el-table-column prop="reply_content" label="反馈内容" show-overflow-tooltip>

            </el-table-column>
            
            <el-table-column fixed="right" label="操作" width="190">
                <template slot-scope="scope">
                    <span class="pointer"  @click="reply(scope.row)" >回复</span>
                    <span class="pointer margin_left_10" @click="toDelete(scope.row)">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="addLabelCategoryDialog">
            <el-dialog :title="title" :visible.sync="dialog" :modal-append-to-body='false'>
                <div class="folder">
                    <el-form label-position="right" label-width="120px" :model="stateObj">
                        <el-form-item label="回复内容：" class="margin_top_20">
                            <el-input v-model="stateObj.reply_content" class="custom_input_350" type="textarea"
                                :rows="4"></el-input>
                        </el-form-item>

                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">

                    <el-button type="primary" class="saveBtn" @click="submit">保存</el-button>
                    <el-button @click="dialog = false" class="cancel">取 消</el-button>
                </span>
            </el-dialog>
        </div>

    </div>
</template>

<script>
        import { replyFeedback ,updateFieldStatus} from "@/api/information";
    export default {
        props: ["tableData"],
        name: '',
        data() {
            return {
                value: true,
                title: "回复",
                dialog: false,
                stateObj: {
                    id: "",
                    reply_content: "",
                }
            }
        },
        methods: {
            reply(row) {
                this.stateObj.reply_content=""
                this.stateObj.id=row.id
                this.dialog = true
            },
            submit(){
                const that=this
                replyFeedback(this.stateObj).then((res) => {
                    if (res.code == 200) {
                        that.$parent.$parent.getDataList()
                        this.dialog = false
                    } else {
                      
                    }
                }).catch((error) => {
                });
            },
            toDelete(row) {
                this.$confirm('此操作将删除该条消息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    updateFieldStatus({ id: row.id }).then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.$parent.$parent.getDataList()
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        }
    }
</script>

<style>
    .addLabelCategoryDialog .el-dialog {
        width: 600px;
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 1px rgba(0, 0, 0, 0.29);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
    }
</style>